<template>
  <div class="home">
    <div class="container mt-3 mb-3">
      <!-- Desktop -->
      <div
        class="row no-gutters d-none d-md-flex"
        style="min-height: 75vh !important"
      >
        <div class="col-6 my-auto">
          <h1 class="display-1 fw-700 text-black" id="hero">
            Bank from <br />anywhere
          </h1>
          <p class="text-black mt-4 mb-4 fw-400 w-75">
            Skip going in-branch or waiting on hold to speak to your bank. Use
            Nimble’s text-first function instead. Chat with a bank manager from
            wherever you are without wasting your day.
          </p>
          <button
            href="sms:+16606565312"
            class="btn btn-primary fw-400 rounded-pill d-inline"
            v-on:click="showChat"
          >
            Get Started
          </button>
        </div>
        <div class="col-6 my-auto">
          <img
            src="../assets/hero-img.png"
            class="d-block mx-auto"
            style="width: 90% !important"
          />
        </div>
      </div>
      <!-- Mobile -->
      <div
        class="row no-gutters d-flex d-md-none mt-5"
        style="min-height: 75vh !important"
      >
        <div class="col-12 my-auto text-center">
          <h1 class="display-2 fw-700 text-black" id="hero">
            Bank from<br />
            anywhere
          </h1>
          <p
            class="text-black mt-4 mb-4 fw-400 mx-auto pl-3 pr-3 pl-md-0 pr-md-0 w-75"
          >
            Skip going in-branch or waiting on hold to speak to your bank. Use
            Nimble’s text-first function instead. Chat with a bank manager from
            wherever you are without wasting your day.
          </p>
          <a
            href="sms:+16606565312"
            class="btn btn-primary fw-400 rounded-pill"
          >
            Get Started
          </a>
          <br />
          <img
            src="../assets/hero-img-mobile.png"
            style="width: 90% !important"
            class="mt-5 mx-auto text-center"
          />
        </div>
        <div
          class="col-10 offset-1 card secondary-bg p-4 text-white border-0 mt-3 mb-5"
        >
          <h1 class="display-4 fw-500">
            Text First<br />
            Function
          </h1>
          <p class="fw-400 mt-3">
            Get quick answers from wherever you are by communicating with us
            over SMS. From ordering a new card to initiating an urgent request,
            your bank manager will handle it for you 100% remotely.
          </p>
          <p class="fw-600 mt-3">
            With nimble, you never have to worry about getting a text back.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  methods: {
    showChat: function () {
      window.FrontChat("show");
    },
  },
};
</script>

<style>
@media (max-width: 576px) {
  #hero {
    font-size: 3.5rem;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  #hero {
    font-size: 5rem;
  }
}
@media (min-width: 769px) {
  #hero {
    font-size: 4.5rem;
  }
}
</style>
