<template>
  <div>
    <div class="fluid-container d-block d-md-none" id="nav">
      <div class="row no-gutters">
        <!-- Mobile -->
        <div class="col-12">
          <b-navbar>
            <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

            <b-navbar-brand class="mx-auto text-center d-block"
              ><img
                src="../assets/logo_white.png"
                style="height: 5vh !important"
            /></b-navbar-brand>
          </b-navbar>
        </div>
      </div>
    </div>

    <!-- Desktop -->
    <div class="container" id="nav">
      <div class="row no-gutters">
        <div class="col-12 d-none d-md-block">
          <b-navbar class="mt-3 mb-3">
            <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>

            <b-navbar-brand
              ><img
                src="../assets/logo_black.png"
                style="height: 5vh !important"
            /></b-navbar-brand>

            <button
              v-on:click="showChat"
              class="btn btn-primary d-block ml-auto fw-400 rounded-pill"
            >
              Get Started
            </button>
          </b-navbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  methods: {
    showChat: function () {
      window.FrontChat("show");
    },
  },
};
</script>

<style>
@media (max-width: 768px) {
  #nav {
    background-color: #b064ed !important;
  }
}
</style>
