<template>
  <div class="container">
    <div class="row no-gutters">
      <div class="col-12">
        <p class="text-center fw-100">
          A Division of [Bank Name]<br />
          Privacy Policy | Terms of Use | [Company Name] &copy; 2022
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBar",
};
</script>
