<template>
  <div id="app">
    <NavBar></NavBar>
    <router-view></router-view>
    <FooterBar />
  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FooterBar from "./components/FooterBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
    FooterBar,
  },
};
</script>

<style>
/*
 * Font Imports
*/
@font-face {
  font-family: "AktivGrotesk";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/font/AktivGrotesk-Hairline.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/font/AktivGrotesk-Light.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/font/AktivGrotesk-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/font/AktivGrotesk-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/font/AktivGrotesk-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/font/AktivGrotesk-XBold.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/font/AktivGrotesk-Black.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: italic;
  font-weight: 100;
  src: url("./assets/font/AktivGrotesk-HairlineItalic.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: italic;
  font-weight: 300;
  src: url("./assets/font/AktivGrotesk-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/font/AktivGrotesk-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: italic;
  font-weight: 500;
  src: url("./assets/font/AktivGrotesk-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: italic;
  font-weight: 600;
  src: url("./assets/font/AktivGrotesk-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/font/AktivGrotesk-XBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "AktivGrotesk";
  font-style: italic;
  font-weight: 800;
  src: url("./assets/font/AktivGrotesk-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LexendDeca";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/font/LexendDeca-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "LexendDeca";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/font/LexendDeca-Light.ttf") format("truetype");
}
@font-face {
  font-family: "LexendDeca";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/font/LexendDeca-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "LexendDeca";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/font/LexendDeca-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "LexendDeca";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/font/LexendDeca-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "LexendDeca";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/font/LexendDeca-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "LexendDeca";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/font/LexendDeca-Black.ttf") format("truetype");
}

/*
 * General Styling
 */
#app {
  overflow-x: hidden !important;
}

body {
  background-color: white !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
}
a {
  color: #212427 !important;
  text-decoration: none !important;
}
.btn {
  white-space: nowrap !important;
}
.btn-primary {
  background-color: #b064ed !important;
  border-color: #b064ed !important;
  color: white !important;
}
h1 {
  font-family: "AktivGrotesk" !important;
}
h2,
h3,
h4,
h5,
h6,
p,
button {
  font-family: "LexendDeca" !important;
}

/*
 * Font Weights
 */
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

/*
 * Colors
 */
.primary-bg {
  background-color: white !important;
}
.secondary-bg {
  background-color: #b064ed !important;
}
.black-bg {
  background-color: #212427 !important;
}
.white-bg {
  background-color: #ffffff !important;
}

.primary-border {
  border-color: #a9e9eb !important;
}
.white-border {
  border-color: #ffffff !important;
}
.white-border {
  border-color: #ffffff !important;
}

.text-black {
  color: #212427 !important;
}
.text-white {
  color: #fff !important;
}
.text-grey {
  color: grey !important;
}
.text-primary {
  color: #a9e9eb !important;
}
.text-secondary {
  color: #b064ed !important;
}
</style>
